.ArtworkListPage .ArtworkListPage__wrapper {
  margin: auto;

  max-width: 300px;
}

.ArtworkListPage .ArtworkGrid {
  margin-bottom: 0;
}

.ArtworkListPage {
  margin: auto;
  margin-bottom: 100px;
  background-color: white;
}

@media only screen and (min-width: 769px) {
  .ArtworkListPage .ArtworkListPage__wrapper {
    max-width: 640px;
  }
}
