.ArtistDescriptionPage {
  padding-top: 100px;
  margin: auto;
  background: white;
  margin-bottom: 100px;
  width: 300px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ArtistDescriptionPage__HTML-wrapper {
  margin-bottom: 50px;
}

.ArtistDescriptionPage h3 {
  letter-spacing: 2px !important;
}

@media only screen and (min-width: 769px) {
  .ArtistDescriptionPage {
    width: 640px;
  }
}
