.NavHamburgerIcon {
  display: flex;
  align-items: center;
  margin: auto;
  height: 50px;
}

.NavHamburgerIcon__wrapper {
  width: 1.875rem;
  height: 1.4rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  transform-origin: right;
}

.NavHamburgerIcon__wrapper--unclickable {
  pointer-events: none;
}

.NavHamburgerIcon__wrapper span {
  display: block;
  position: absolute;
  height: 0.2rem;
  width: 50%;
  background: rgba(4, 28, 54, 1);
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.NavHamburgerIcon__wrapper span:nth-child(even) {
  left: 50%;
  border-radius: 0 0.28rem 0.28rem 0;
}

.NavHamburgerIcon__wrapper span:nth-child(odd) {
  left: 0rem;
  border-radius: 0.28rem 0 0 0.28rem;
}

.NavHamburgerIcon__wrapper span:nth-child(1),
.NavHamburgerIcon__wrapper span:nth-child(2) {
  top: 0rem;
}

.NavHamburgerIcon__wrapper span:nth-child(3),
.NavHamburgerIcon__wrapper span:nth-child(4) {
  top: 0.5625rem;
}

.NavHamburgerIcon__wrapper span:nth-child(5),
.NavHamburgerIcon__wrapper span:nth-child(6) {
  top: 1.125rem;
}

.NavHamburgerIcon__wrapper--open span:nth-child(1),
.NavHamburgerIcon__wrapper--open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.NavHamburgerIcon__wrapper--open span:nth-child(2),
.NavHamburgerIcon__wrapper--open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.NavHamburgerIcon__wrapper--open span:nth-child(1) {
  left: 0.156rem;
  top: 0.219rem;
}

.NavHamburgerIcon__wrapper--open span:nth-child(2) {
  left: calc(50% - 0.156rem);
  top: 0.219rem;
}

.NavHamburgerIcon__wrapper--open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.NavHamburgerIcon__wrapper--open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.NavHamburgerIcon__wrapper--open span:nth-child(5) {
  left: 0.156rem;
  top: 0.906rem;
}

.NavHamburgerIcon__wrapper--open span:nth-child(6) {
  left: calc(50% - 0.156rem);
  top: 0.906rem;
}
