.NavLogo {
  height: 50px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NavBar__logo-image {
  width: 300px;
  color: black;
}
