.ArtworkPage__image {
  width: 100%;
}

.ArtworkPage {
  padding-top: 100px;
  width: 300px;
  margin: auto;
  margin-bottom: 100px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.ArtworkPage__details {
  padding: 20px 0;
}

.ArtworkPage__details-row {
  display: flex;
}

.ArtworkPage__details-row-header {
  width: 36%;
  margin-right: 4%;
  flex-shrink: unset;
}

@media only screen and (min-width: 769px) {
  .ArtworkPage {
    width: 640px;
  }
  .ArtworkPage__details {
    width: 300px;
    margin: auto;
  }
}
