* {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  margin: 0;
}

h1 {
  font-size: 30px !important;
  padding: 0 !important;
  line-height: 1 !important;
}

h2 {
  font-size: 20px;
  margin-block-start: 10px;
  margin-block-end: 10px;
}

h4,
p {
  margin-block-start: 10px;
  margin-block-end: 10px;
}
