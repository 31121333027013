.BackLink {
  color: white;
  padding: 10px 20px;
  background-color: black;
  border-radius: 5px;
  border: solid 2px black;
  width: fit-content;
}

.BackLink__link {
  text-decoration: none;
  display: flex;
  color: inherit;
  align-items: center;
}

.BackLink__text {
  color: inherit;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 20px;
}
