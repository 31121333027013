.ArtworkGridItem__image {
  width: 100%;
  display: block;
}

.ArtworkGridItem {
  width: 300px;
  margin: auto;
  margin-bottom: 20px;
  position: relative;
}

.ArtworkGridItem__caption {
  color: black;
}

.ArtworkGridItem__link {
  text-decoration: none;
}

.ArtworkGridItem__title-wrapper {
  display: none;
}

.ArtworkGridItem__caption {
  display: block;
}

@media (hover: hover) {
  .ArtworkGridItem__title-wrapper {
    position: absolute;
    top: 0;
    color: white;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 20px;
  }

  .ArtworkGridItem__caption {
    display: none;
  }
}
