.Footer {
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 0;
  z-index: -1;
}

.Footer__content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: space-evenly;
}

.Footer__icons-wrapper {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer__social-link {
  color: inherit;
  margin: 0 5px;
}
