.Loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiCircularProgress-root {
  width: 150px !important;
  height: 150px !important;
  z-index: 20;
  color: black !important;
}
